import React from 'react'
import PropTypes from 'prop-types'


// Helpers functions 

const isSpacingTop = (isPresent) =>
  isPresent
  ? '60px'
  : '0px'

const isSpacingBottom = (isPresent) =>
  isPresent ? '60px' : '0px'

export default class Wrapper extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { spacingBottom, spacingTop, ...rest } = this.props;
    return (
      <div 
        style={{
          maxWidth: '745px',
          // If spacingTop | spacingBottom property exists, sets it by spacing[Top | Bottom] function
          margin: `${isSpacingTop(spacingBottom)} auto ${isSpacingBottom(spacingTop)} auto`,
          padding: '0 15px'
        }} {...rest}>
        {this.props.children}
      </div>
    )
  }
}

// SEO.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string,
//   image: PropTypes.string,
//   article: PropTypes.bool,
// }

Wrapper.propTypes = {
  spacingBottom: PropTypes.bool
}
import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"

import styles from "./button.module.scss"
import shakeStyles from "./shake.scss"
import classnames from "../../helpers/classnames"

function buttonStyle(size) {
  switch (size) {
    case "large":
      return styles.big
    case "header":
      return styles.headerMenuButton
    case "popup":
      return styles.popup
    case "ebooksBig":
      return styles.ebooksButtonBig
    case "ebooksSmall":
      return styles.ebooksButtonSmall
    case "ebooksLong":
      return styles.ebooksButtonLong
    case "cookie":
      return styles.cookieButton
    case "showMore":
      return styles.readMoreButton
    case "sectionButton":
      return styles.sectionButton
    default:
      return styles.small
  }
}

const Button = props => {

  const {
    className,
    background,
    backgroundPosition,
    size,
    children,
    shake,
    href,
    outline = false,
    border = false,
    inline = false,
    width=null,
    style,
    ...rest
  } = props;
  const [shakeClass, setShakeClass] = useState(false);
  const [shakeInterval, setShakeInterval] = useState(null);

  const doShake = e => {
    setShakeClass(true);
    setTimeout(() => setShakeClass(false), 1000);
  }

  // if shake prop changes, update interval - shake button every 4s
  useEffect(() => {
    clearInterval(shakeInterval);
    if (shake) {
      setShakeInterval(setInterval(() => doShake(), 6000));
    }
  }, [shake]);

  const classes = classnames(
    className,
    styles.button,
    buttonStyle(size),
    shakeClass ? " shake-little" : null,
    border ? styles.buttonBordered : null,
    inline ? styles.buttonInline : null,
    outline ? styles.buttonOutlined : null,
  );

  const localStyle = {
    width,
  };

  if (href) {
    return (
      <a
        style={Object.assign(localStyle, style)}
        href={href}
        className={classes + ` ${styles.buttonLink}`}
        {...rest}
      >
        {children}
      </a>
    )
  } else {
    if (size === "sectionButton") {
      return (
        <div
          style={Object.assign(localStyle, style)}
          className={backgroundPosition === "top" ? styles.sectionButtonWrapperTop : styles.sectionButtonWrapperBottom}
        >
          <button
            className={classes}
            {...rest}
          >
            {children}
          </button>
          <div
  style={{ background: background ? background : "#f0f0f0" }}
  className={backgroundPosition === "top" ? styles.sectionButtonWrapperTopBackground : styles.sectionButtonWrapperBottomBackground}/>
        </div>
      )
    }

    return (
      <button
        {...rest}
        style={Object.assign(localStyle, style)}
        className={classes}
      >
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  size: PropTypes.string,
  shake: PropTypes.bool
}

export default Button

import React, { Component } from 'react'
import Wrapper from '../components/wrapper'
import Title from '../components/title'
import Logo from '../components/logo'
import { Link } from 'gatsby'
import Button from '../components/button'

export default class Page404 extends Component {
  render() {
    return (
      <Wrapper>
        <Logo type={'black'} style={{ margin: '40px auto' }} />
        <Title>Hey, the page you're looking for does not exist.</Title>
        <div style={{ textAlign: 'center' }}>
          <Link to={'/'}>
            <Button>Take me home</Button>
          </Link>
        </div>
      </Wrapper>
    )
  }
}

// merges all classes into one string
export default function classnames(...arr) {
  let classes = [];
  if (!arr) {return "";}
  arr.forEach(i => {
    if (typeof i === "undefined" || i === null) { return; }

    // flatten if array
    if (Array.isArray(i)) {
      classes = [...i.flat(), ...classes];
    // else split string
    } else {
      classes = [...i.split(" "), ...classes];
    }
  });
  return classes.join(" ");
}
